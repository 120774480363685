import React, { Component } from "react";
//import { Link } from "react-router-dom";

export default class UserDetail extends Component {
    render() {
        return (
            <div>
				<h1>Not Found</h1>
			
			</div>
        );
    }
}